
import { mapGetters, mapActions } from "vuex";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { FormatDate, FormatHour } from "@/shared/helpers/dateHelpers";
import { CourseAttendanceTableColumns } from "@/shared/utils/tableHeaders";
import { FormatList } from "@/shared/helpers/filterHelpers";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AttendanceDayPage",
  components: { BaseBreadCrumbs },
  computed: {
    ...mapGetters("course", ["getCourse"]),
    ...mapGetters("attendance", ["getCourseAttendanceList"]),
    tableHeaders() {
      return FormatList(CourseAttendanceTableColumns, "text");
    },
    attendanceItems() {
      let attendanceTable: any[] = [];
      if ((this as any).getCourse.plan) {
        (this as any).getCourse.plan.schedules.forEach((item: any, index: any) => {
          const date = FormatDate(item.start);
          const startHour = FormatHour(item.start);
          const endHour = FormatHour(item.end);
          const hours = startHour + " - " + endHour;
          const completed = "";
          const attendanceItem = {
            id: index,
            date: date,
            hours: hours,
            title: item.title,
            completed: completed,
            status: "Ny",
            comment: "",
          };
          attendanceTable.push(attendanceItem);
        });
      }
      return attendanceTable;
    },
  },
  methods: {
    ...mapActions("course", ["fetchCourse"]),
    ...mapActions("attendance", ["fetchCourseAttendanceList"]),
    linkAttendance(event: any) {
      const courseId = this.$router.currentRoute.params.id;
      this.$router.push(`/course/${courseId}/attendance/${event.id}`);
    },
  },
  async mounted() {
    const courseId = this.$router.currentRoute.params.id;
    await (this as any).fetchCourse({ courseId: courseId });
    if ((!this as any).getCourseAttendanceList.length) {
      await (this as any).fetchCourseAttendanceList(courseId);
    }
  },
});
