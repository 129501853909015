var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('BaseBreadCrumbs'), _vm.attendanceItems.length <= 0 ? _c('div', {
    staticClass: "title font-weight-light text-center",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" Ingen frammøteliste funnet ")]) : _c('v-card', {
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_vm._v(" Fremmøteregistrering ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.tableHeaders,
      "items": _vm.attendanceItems,
      "sort-by": "id",
      "sort-desc": false,
      "data-cy": "courseAttendanceTable"
    },
    on: {
      "click:row": _vm.linkAttendance
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }